import { GoChevronLeft } from "react-icons/go";
import { useState } from "react";
import classNames from "classnames";

function FAQSection ({children, title}) {
	const [isOpen, setIsOpen] = useState(false);

	const classes = classNames(
		"flex flex-row items-center align-middle justify-between transition-colors duration-300 cursor-pointer",
		isOpen ? "bg-[#ff9600] text-white rounded" : "text-black bg-transparent border-b-2 border-[#ff9600]"
	);

	const chevronClasses = classNames(
		"lg:text-[1.7vw] lg:mr-[1%]",
		"text-[5vw] font-bold mr-[2%] transition-all duration-300",
		isOpen ? "rotate-[-90deg] text-white" : "rotate-0 text-black"
	);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="lg:w-[65%]
						 w-[100%] flex flex-col">
			<div className={classes} onClick={handleClick}>
				<p className="lg:text-[1.2vw]
								text-[3vw] font-bold px-6 py-2">{title}</p>
				<GoChevronLeft className={chevronClasses} />
			</div>
			{isOpen && <div className="lg:border-b-2
											transition-colors duration-300 flex flex-col items-center h-fit border-b-[2px] border-[#ff9600]">
				{children}
			</div>}
		</div>
	)
}

export default FAQSection;