import SunFull from "../images/Asset_etoile_orange4_full.svg"
import SunEmpty from "../images/Asset_etoile_orange4.svg"
import classNames from "classnames";


function Checkbox ({text, value, change, margins}) {
	const onChange = () => {
		change();
	};
	
	const classes = classNames(
		"lg:w-[1vw] lg:h-[1vw]",
		"w-[3.5vw] h-[3.5vw] cursor-pointer inline-block",
		margins,
	);

	return (
		<label className="w-full flex flex-row flex-wrap mb-[2%]">
			<input type="checkbox" className="invisible" value={value} onChange={onChange}/>
			{value ? <img src={SunFull} className={classes} /> : <img src={SunEmpty} className={classes} />}
			{text}
		</label>
	)
}

export default Checkbox;