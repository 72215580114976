import Background from "../images/Img_texture-papier_seamless_4K.png"


function LegalMentionsPage () {
	return (
		<div className="lg:text-[1vw]
							w-full h-fit min-h-screen text-[3vw] text-black flex flex-col flex-wrap" style={{ backgroundImage: `url(${Background})` }}>
			<div className="mt-[5%] ml-[3%] mr-[3%]">
				<p className="font-bold">1. Coordonnées et activité</p>
				<p className="ml-[3%]">
					NÆRIS TATTOO (Entreprise individuelle) <br/>
					Gérant : Naëlick CATTANEO <br/>
					Siège social : 239 av. d'Argenteuil, 92270 Bois-Colombes <br />
					Adresse électronique : naeris.ttt[at]gmail.com <br />
					SIRET de l'établissement : 97895072300012 <br />
					Activité exercée : tatouage <br />
				</p>

				<p className="font-bold">2. Gestion et utilisation des données personnelles des utilisateurs</p>
				<p className="ml-[3%]">
					Quiconque peut visiter le site https:/naeris.fr sans avoir à décliner son identité ou fournir des informations personnelles. <br/>
					Certaines fonctionnalités du site nécessitent cependant la collecte d'informations (par exemple : Prénom, Adresse électronique), notamment afin d'établir une prise de contact ou de souscrire une commande. La collecte de ces informations est impossible sans l'autorisation explicite de l'utilisateur, puisque ce dernier procède lui-même à leur saisie. L'entreprise NÆRIS TATTOO procède à la collecte de ces informations dans le seul et unique but de l'exercice de son activité. <br/>
					Toutes les images utilisées dans la rubrique "TATTOOS" sont postées exclusivement sous conditions d'un accord de consentement écrit de la personne concernée. <br/>
					De plus, conformément à la loi Informatique et Libertés du 6 janvier 1978 et le Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, chaque client dispose d’un droit d’accès, de rectification, de rétractation, d’effacement et de portabilité sur l’ensemble de ses données et des représentations le concernant, en déposant une demande par courriel à l’adresse électronique listée ci-avant, avec justification de son identité. <br/>
				</p>
				<p className="font-bold">4. Gestion des cookies</p>
				<p className="ml-[3%]">
					Ce site n'utilise pas de cookies.
				</p>
				<p className="font-bold">5. Propriété intellectuelle</p>
				<p className="ml-[3%]">
					L'entreprise NÆRIS TATTOO est le seul et unique propriétaires des droits d'usage des éléments relatifs à https://naeris.fr, à l'exception de quelques icônes issues de https://react-icons.github.io. De ce fait, toute reproduction, représentation, modification, publication ou adaptation du tout ou d'une partie des éléments du site est interdite.
				</p>
				<p className="font-bold">5. Création et hébergement du site</p>
				<p className="ml-[3%]">
					Développement web : Rose GAILLARD <br/>
					Site hébergé par OVH SAS, 2 rue Kellermann,59100 Roubaix, France <br/>
					Numéro de téléphone : 1007
				</p>
			</div>
		</div>
	)
}

export default LegalMentionsPage;