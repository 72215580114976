import classNames from "classnames";

function TattooPreview ({src, isActive, handleClick}) {
	const classes = classNames (
		"cursor-pointer mb-[10%] border-2 transition-all duration-300 ease-in-out hover:brightness-100",
		isActive ? " border-[#ff9600]" : "border-transparent brightness-50"
	)

	return (
		<div className={classes}>
			<div>
				<img src={src} className="w-full" onClick={() => handleClick(src)} />
			</div>
		</div>
	)
}

export default TattooPreview;