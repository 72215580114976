import TapisBG from "../images/Img_tapis.png"
import Logo from "../images/stamp_logo.svg.png"

function MainPage () {
	return (
		<div className="lg:bg-contain
							w-full h-screen flex justify-center items-center flex-wrap bg-auto bg-center" style={{ backgroundImage: `url(${TapisBG})` }}>
			<img src={Logo} className="lg:size-[35vw] lg:mb-[3%]
											size-[90vw] mb-[20%]"/>
		</div >
	)
}

export default MainPage;