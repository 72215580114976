import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
	name: 'contact',
	initialState: {
		eventFull: false,
		dropdownSelection: null,
		location: "Salon",
		selected: "Projet",
		waitList: false,
		selectedFlash: "0",
		object : "",
	},
	reducers: {
		changeEventFull(state, action) {
			state.eventFull = action.payload;
		},
		changeDropdownSelection(state, action) {
			state.dropdownSelection = action.payload;
		},
		changeLocation(state, action) {
			state.location = action.payload;
			changeObject();
		},
		changeSelected(state, action) {
			state.selected = action.payload;
			changeObject();
		},
		changeWaitList(state, action) {
			state.waitList = action.payload;
		},
		changeSelectedFlash(state, action) {
			state.selectedFlash = action.payload;
		},
		changeObject(state, action) {
			if (action.payload)
				state.object = action.payload;
			else {
				state.object = (state.location === "Salon" ? "Salon " : (state.dropdownSelection?.value ? state.dropdownSelection?.value + " " : ""))
				if (state.selected === "Projet")
					state.object += "Projet sur mesure"
				else
					state.object += "Réservation flash n°" + state.selectedFlash;
			}
		}
	}
});

export const {changeEventFull, changeDropdownSelection, changeLocation, changeSelected, changeWaitList, changeSelectedFlash, changeObject } = contactSlice.actions;
export const contactReducer = contactSlice.reducer;