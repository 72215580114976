import Route from "./Route";
import MainPage from "./MainPage";
import ContactPage from "./ContactPage";
import EventsPage from "./EventsPage";
import TattoosPage from "./TattoosPage";
import FlashbookPage from "./FlashbookPage";
import FAQPage from "./FAQPage";
import LegalMentionsPage from "./LegalMentionsPage";

function ContentWindow ({goToTop}) {
	return (
		<div className="w-screen h-fit min-h-dvh">
			<Route path="/">
				<MainPage />
			</Route>
			<Route path="/flashbook">
				<FlashbookPage goToTop={goToTop}/>
			</Route>
			<Route path="/events">
				<EventsPage goToTop={goToTop}/>
			</Route>
			<Route path="/tattoos">
				<TattoosPage />
			</Route>
			<Route path="/faq">
				<FAQPage goToTop={goToTop}/>
			</Route>
			<Route path="/contact">
				<ContactPage goToTop={goToTop}/>
			</Route>
			<Route path ="/mentions-legales">
				<LegalMentionsPage />
			</Route>
		</div>
	)
}

export default ContentWindow;