import { RiInstagramLine } from "react-icons/ri";
import { RiFacebookCircleLine } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import NavigationContext from "../context/navigation";
import { useContext } from "react";

function Socials ({goToTop}) {
	const { navigate } = useContext(NavigationContext);

	const handleClickLegalMentions = (event) => {
		if (event.metaKey || event.ctrlKey)
			return;
		event.preventDefault();

		navigate("/mentions-legales");
		goToTop();
	};

	return (
		<div className="lg:mt-[0%] lg:mb-[0%]
							flex flex-col flex-wrap h-fit w-fit ml-[4%] mt-[3%] mb-[3%]">
			<p className="lg:text-[1vw]
							font-bold text-[3vw] mb-[2%]">CONTACT</p>
			<a href="https://www.instagram.com/naeris.ttt/" className="flex flex-row mb-[0.5%]">
				<RiInstagramLine className="lg:text-[1.8vw]
												text-[5vw] mr-[2%]" />
				<p className="lg:text-[1vw]
								text-[3vw] pt-[0.5%] align-bottom h-fit underline">@naeris.ttt</p>
			</a>
			<a href="mailto:naeris.ttt@gmail.com" className="flex flex-row mb-[0.5%]">
				<MdOutlineMailOutline className="lg:text-[2vw]
													text-[6vw] mr-[2%]"/>
				<p className="lg:text-[1vw] lg:pt-[0.7%]
								text-[3vw] pt-[1.5%] align-bottom h-fit underline">naeris.ttt[at]gmail.com</p>
			</a>
			<a href="https://www.facebook.com/profile.php?id=61552794103897" className="flex flex-row mb-[0.5%]">
				<RiFacebookCircleLine className="lg:text-[1.8vw]
													text-[5vw] mr-[2%]" />
				<p className="lg:text-[1vw] lg:pt-[0.5%]
								text-[3vw] pt-[1%] align-bottom h-fit underline">NÆRIS Tattoo</p>
			</a>
			<a className="lg:text-[0.8vw]
							cursor-pointer text-[2.8vw]" onClick={handleClickLegalMentions}>Mentions Légales</a>
		</div>
	)
}

export default Socials;