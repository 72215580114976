import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
	name: 'data',
	initialState: {
		eventsData: null,
		tattoosData: null,
		flashsData: null,
		planchesData: null,
		planchesOrderData: null,
	},
	reducers: {
		changeEventsData(state, action) {
			state.eventsData = action.payload
		},
		changeTattoosData(state, action) {
			state.tattoosData = action.payload;
		},
		changeFlashsData(state, action) {
			state.flashsData = action.payload;
		},
		changePlanchesData(state, action) {
			state.planchesData = action.payload;
		},
		changePlanchesOrderData(state, action) {
			state.planchesOrderData = action.payload;
		}

	},
});

export const {changeEventsData, changeTattoosData, changeFlashsData, changePlanchesData, changePlanchesOrderData} = dataSlice.actions;
export const dataReducer = dataSlice.reducer;