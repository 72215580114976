import { useState, useEffect, useContext } from "react";
import Checkbox from "./Checkbox";
import { useSelector } from "react-redux";
import Tattoo from "./Tattoo";
import Masonry from "react-responsive-masonry";
import TattooModal from "./TattooModal";
import Background from "../images/img_fond-noir_seamless_4K.png"
import MobileContext from "../context/mobile";
import { GoChevronDown } from "react-icons/go";
import classNames from "classnames";

function TattoosPage () {
	const {isMobile} = useContext(MobileContext);

	const tattoosData = useSelector((state) => {
		return state.data.tattoosData;
	});

	const [filters, setFilters] = useState([]);
	const [selectedTattoo, setSelectedTattoo] = useState();
	const [selectedPreviewPicture, setSelectedPreviewPicture] = useState();
	const [filtersOpen, setFiltersOpen] = useState(false);

	const mobileChevronClasses = classNames(
		"text-[6vw] transition-transform duration-200",
		filtersOpen ? "rotate-0" : "rotate-180",
	);

	useEffect(() => {
		const handleKeydown = (event) => {
			switch (event.key) {
				case ("ArrowLeft"):
					handleClickTattooNavigation(-1)
					return;
				case ("ArrowRight"):
					handleClickTattooNavigation(1)
					return;
			}
		}

		document.addEventListener("keydown", handleKeydown);

		return (() => {
			document.removeEventListener("keydown", handleKeydown);
		})
	}, [selectedTattoo]);

	const renderedTattoosData = tattoosData?.map((tattoo) => {
		return {
			date: new Date(tattoo.data.date),
			type: tattoo.data.type,
			imageOne: tattoo.data.imageone?.url,
			imageTwo: tattoo.data.imagetwo?.url,
			imageThree: tattoo.data.imagethree.url,
			imageFour: tattoo.data.imagefour.url,
			id: tattoo.data?.id,
		}
	})

	const sortedTattoosData = renderedTattoosData?.sort((a, b) => b.date - a.date);

	const handleTattooClose = () => {
		setSelectedTattoo(null);
	}

	const handleClickTattoo = (tattoo) => {
		setSelectedTattoo(tattoo);
		setSelectedPreviewPicture(tattoo.imageOne);
	};

	const handleClickTattooNavigation = (step) => {
		console.log(step);
		if (step === 1)
		{
			if (getIndexOfTattoo(selectedTattoo) === sortedTattoosData.length - 1)
			{
				setSelectedTattoo(sortedTattoosData[0]);
				setSelectedPreviewPicture(sortedTattoosData[0].imageOne);
			}
			else
			{
				setSelectedTattoo(sortedTattoosData[getIndexOfTattoo(selectedTattoo) + 1])
				setSelectedPreviewPicture(sortedTattoosData[getIndexOfTattoo(selectedTattoo) + 1].imageOne)
			}
		}
		else if (step === -1)
		{
			if (getIndexOfTattoo(selectedTattoo) === 0)
			{
				setSelectedTattoo(sortedTattoosData[sortedTattoosData.length - 1])
				setSelectedPreviewPicture(sortedTattoosData[sortedTattoosData.length - 1].imageOne)
			}
			else
			{
				setSelectedTattoo(sortedTattoosData[getIndexOfTattoo(selectedTattoo) - 1])
				setSelectedPreviewPicture(sortedTattoosData[getIndexOfTattoo(selectedTattoo) - 1].imageOne)
			}
		}
	}

	const handleClickPreview = (image) => {
		setSelectedPreviewPicture(image);
	}

	const renderedTattoos = sortedTattoosData?.map((tattoo) => {
		let isActive = false;
		if (filters.length === 0 || filters.indexOf(tattoo.type) > -1)
			isActive = true;			
		return <Tattoo isActive={isActive} tattoo={tattoo} handleClick={handleClickTattoo}/>
	})

	const onClickFilterMobile = () => {
		setFiltersOpen(!filtersOpen);
	}

	const onChangeFilters = (value) => {
		if (value === "All")
			setFilters([]);
		else
		{
			if (filters.indexOf(value) > -1) {
				let tmp = [...filters];
				const index = tmp.indexOf(value);
				tmp.splice(index, 1);
				setFilters(tmp);
			}
			else
				setFilters([...filters, value]);
		}
	}

	const checkFilters = (value) => {
		if (value === "All")
		{
			if (filters.length === 0)
				return true;
			else
				return false;
		}

		if (filters.indexOf(value) > -1)
			return true;
		return false;
	}

	const getIndexOfTattoo = (tattoo) => {
		for (let i = 0; i < sortedTattoosData.length; i++)
		{
			if (sortedTattoosData[i].imageOne === tattoo.imageOne)
				return i;
		}
		return ;
	}

	return (
		<div className="w-full h-fit min-h-screen flex flex-col lg:pb-[3%] pb-[0%] text-white bg-contain font-Montserrat" style={{ backgroundImage: `url(${Background})` }}>
			<div className="w-full h-fit min-h-screen flex flex-row">
				{!isMobile && <div className="w-[20%] h-fit ml-[5%] sticky top-[8%] flex flex-col">
					<p className="text-[0.8vw] mb-[0%] ml-[4%] invisible">Un flash est un tatouage unique qui ne sera tatoué qu'une fois. <br /> Si un motif est marqué d'une pastille orange, alors il a déjà été réservé.</p>
					<div>
						<p className="mt-[5%] text-left text-[1.3vw] mb-[5%] font-bold">Collections</p>
						<div className="w-full text-[1.1vw] ml-[3%] mb-[50%]">
							<Checkbox text="Tous" value={checkFilters("All")} change={() => onChangeFilters("All")} margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Animaux" value={checkFilters("Animaux")} change={() => onChangeFilters("Animaux")} margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Insectes" value={checkFilters("Insectes")} change={() => onChangeFilters("Insectes")} margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Objets" value={checkFilters("Objets")} change={() => onChangeFilters("Objets")}  margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Plantes" value={checkFilters("Plantes")} change={() => onChangeFilters("Plantes")}  margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Mains" value={checkFilters("Mains")} change={() => onChangeFilters("Mains")} margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Squelettes" value={checkFilters("Squelettes")} change={() => onChangeFilters("Squelettes")}  margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Typographies" value={checkFilters("Typographies")} change={() => onChangeFilters("Typographies")}  margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Visages" value={checkFilters("Visages")} change={() => onChangeFilters("Visages")}  margins="mt-[2.3%] mr-[3%]"/>
							<Checkbox text="Autres" value={checkFilters("Autres")} change={() => onChangeFilters("Autres")} margins="mt-[2.3%] mr-[3%]"/>
						</div>
					</div>
				</div>}
				<Masonry columnsCount={isMobile ? 2 : 4} gutter={"10px"} className="h-fit ml-[9%] mt-[5%] mr-[10%]">
					{renderedTattoos}
				</Masonry>
				{selectedTattoo != null && <TattooModal onClose={handleTattooClose} onClickTattooNavigation={handleClickTattooNavigation} tattoo={selectedTattoo} selectedPicture={selectedPreviewPicture} handleClickPreview={handleClickPreview}/>}
			</div>
			{isMobile && <div className="flex flex-col-reverse w-screen h-fit sticky bottom-[0%] z-10 bg-contain" style={{ backgroundImage: `url(${Background})` }}>
				{filtersOpen && <div className="flex flex-row justify-between py-[2%] border-x-[3px] border-white">
					<div className="flex flex-col w-[40%] ml-[5%] text-[5vw]">
						<Checkbox text="Tous" value={checkFilters("All")} change={() => onChangeFilters("All")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Animaux" value={checkFilters("Animaux")} change={() => onChangeFilters("Animaux")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Insectes" value={checkFilters("Insectes")} change={() => onChangeFilters("Insectes")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Objets" value={checkFilters("Objets")} change={() => onChangeFilters("Objets")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Plantes" value={checkFilters("Plantes")} change={() => onChangeFilters("Plantes")} margins="mt-[5%] mr-[5%]" />
					</div>
					<div className="flex flex-col w-[40%] mr-[5%] text-[5vw]">
						<Checkbox text="Mains" value={checkFilters("Mains")} change={() => onChangeFilters("Mains")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Squelettes" value={checkFilters("Squelettes")} change={() => onChangeFilters("Squelettes")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Visages" value={checkFilters("Visages")} change={() => onChangeFilters("Visages")} margins="mt-[5%] mr-[5%]" />
						<Checkbox text="Autres" value={checkFilters("Autres")} change={() => onChangeFilters("Autres")} margins="mt-[5%] mr-[5%]" />
					</div>
				</div>}
				<div className="flex flex-row items-center justify-center text-[5vw] py-[2%] cursor-pointer border-t-[3px] border-x-[3px] rounded-t border-white" onClick={onClickFilterMobile}>
					<p>Voir les collections</p>
					<GoChevronDown className={mobileChevronClasses} />
				</div>
			</div>}
		</div>
	)
}

export default TattoosPage;