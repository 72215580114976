import Logo13 from "../images/Asset_logo_M13.svg"
import Logo14 from "../images/Asset_logo_M14.svg"
import LogoL from "../images/Asset_logo_L.svg"

function Location () {
	return (
		<div className="lg:w-[15%] lg:mt-[0%] lg:mb-[0%]
							w-[50%] flex flex-col flex-wrap h-fit mr-[2%] mt-[3%] mb-[3%]">
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right">L'ENCANAILLERIE</p>
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right">23 bis rue Henri Barbusse</p>
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right">92110 Clichy</p>
			<div className="flex flex-row flex-wrap w-full h-fit justify-end">
				<p className="lg:text-[1vw]
								text-[3vw] mr-[2%] mb-[1%] text-right">Mairie de Clichy</p>
				<img src={Logo13} className="lg:w-[1.5dvw] lg:h-[1.5dvw] 
												w-[4.5dvw] h-[4.5dvw] max-h-[30px] max-w-[30px]"/>
			</div>
			<div className="flex flex-row flex-wrap w-full h-fit justify-end">
				<p className="lg:text-[1vw]
								text-[3vw] mr-[2%] mb-[1%] text-right">Porte de Clichy</p>
				<img src={Logo14} className="lg:w-[1.5dvw] lg:h-[1.5dvw] 
												w-[4.5dvw] h-[4.5dvw] max-h-[30px] max-w-[30px]" />
			</div>
			<div className="flex flex-row flex-wrap w-full h-fit justify-end">
				<p className="lg:text-[1vw]
								text-[3vw] mr-[2%] mb-[1%] text-right">Clichy-Levallois</p>
				<img src={LogoL} className="lg:w-[1.5dvw] lg:h-[1.5dvw] 
												w-[4.5dvw] h-[4.5dvw] max-h-[30px] max-w-[30px]" />
			</div>
		</div>
	)
}

export default Location;