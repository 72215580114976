import { createPortal } from "react-dom";
import { useEffect } from "react";

function Popup ({text, onClose}) {
	useEffect(() => {
		document.body.classList.add('overflow-hidden');

		return () => document.body.classList.remove('overflow-hidden');
	}, []);

	return createPortal(
		<div className="fixed bottom-0 mb-[1%] ml-[44%]">
			<button className="absolute right-[5px] text-white" onClick={onClose}>x</button>
			<div className="flex w-[400px] min-h-[100px] bg-[#ff9600] text-white rounded text-[1vw] px-6 py-4 text-center justify-center items-center">
				<p className="break-all">{text}</p>
			</div>
		</div>,
		document.body
	)
}

export default Popup;