import "./index.css"
import React from "react";
import ReactDOM from "react-dom/client"
import App from "./App"
import { NavigationProvider } from './context/navigation';
import { MobileProvider } from "./context/mobile";
import { PrismicProvider } from '@prismicio/react'
import { client } from './Prismic'
import { Provider } from "react-redux";
import { store } from './store';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
	<PrismicProvider client={client}>
		<Provider store={store}>
			<MobileProvider>
				<NavigationProvider>
					<App />
				</NavigationProvider>
			</MobileProvider>
		</Provider>
	</PrismicProvider>
);