import RadioButton from "./RadioButton";

function RadioButtons ({selected, location, onChange, onChangeLocation}) {
	const isChecked = (value) => selected === value;
	const isCheckedLocation = (value) => location === value;
	
	return (
		<div className="lg:w-[80.5%] lg:ml-[15%] lg:flex-row lg:text-[1.2vw]
							flex flex-col flex-wrap w-[90%] text-[3.2vw]">
			<p className="lg:mb-[0%] mb-[3%]">Type de demande<span className="text-[#ff9600]">*</span></p>
			<div className="lg:w-[70%] lg:justify-evenly lg:mb-[1%]
								flex flex-col w-[100%] mr-[7.5%] mb-[8%]">
				{/* <div className="lg:justify-evenly lg:mb-[2%]
									flex flex-row mb-[4%]">
					<RadioButton text="Réservation de flash" isChecked={isChecked("Resa")} value={"Resa"} change={onChange}></RadioButton>
					<RadioButton text="Projet sur mesure" isChecked={isChecked("Projet")} value={"Projet"} change={onChange}></RadioButton>
				</div> */}
				<div className="lg:justify-evenly
									flex flex-row mb-[0.5%]">
					<RadioButton text="Au salon" isChecked={isCheckedLocation("Salon")} value={"Salon"} change={onChangeLocation}></RadioButton>
					<RadioButton text="En évènement" isChecked={isCheckedLocation("Guest")} value={"Guest"} change={onChangeLocation}></RadioButton>
				</div>
			</div>
		</div>
	)
}

export default RadioButtons;