import classNames from "classnames";

// needed to use style because for some reason tailwind and classNames and string injections don't really work well together ??

function Flash ({data, isActive, isTaken, onClick}) {
	const classes = classNames(
		"transition-all duration-300 bg-no-repeat bg-center bg-contain",
		!isTaken && isActive && "cursor-pointer hover:scale-110",
		isActive ? "": "brightness-50"
	)

	const handleClick = () => {
		if (!isTaken)
			onClick(data);
	}

	return (
		<div onClick={handleClick} className={classes} style={{ backgroundImage: `url(${data.image})`, gridRow: `span ${data.height} / span ${data.height}`, gridColumn: `span ${data.width} / span ${data.width}` }}>
			{isTaken && <img src={data.takenImage} className="w-full h-full"/>}
		</div>
	)
}

export default Flash;