import { useContext } from "react";
import NavigationContext from "../context/navigation";
import classNames from "classnames";

function Link({ to, children, className, activeClassName, goToTop }) {
	const { navigate, currentPath } = useContext(NavigationContext);
	
	const classes = classNames(
		className,
		currentPath === to ? activeClassName : "font-normal text-white"
	);

	const handleClick = (event) => {
		if (event.metaKey || event.ctrlKey)
			return ;
		event.preventDefault();

		navigate(to);
		goToTop();
	};

	return (
		<a className={classes} href={to} onClick={handleClick}>
			{children}
		</a>
	)
}

export default Link;