import { createContext, useEffect, useState } from "react";

const MobileContext = createContext();

function MobileProvider ({children}) {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize)

		return () => window.removeEventListener("resize", handleResize);
	}, [])

	const handleResize = () => {
		if (window.innerWidth >= 1024)
			setIsMobile(false);
		else
			setIsMobile(true);
	}

	return (
		<MobileContext.Provider value={{ isMobile }}>
			{children}
		</MobileContext.Provider>
	)
}

export { MobileProvider };
export default MobileContext;