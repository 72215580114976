import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser"
import ContentWindow from "./components/ContentWindow";
import { useAllPrismicDocumentsByType, useSinglePrismicDocument } from '@prismicio/react'
import { useDispatch } from "react-redux";
import { changeEventsData, changeTattoosData, changeFlashsData, changePlanchesData, changePlanchesOrderData } from "./store";

function App () {
	useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY), []);

	const dispatch = useDispatch();

	const ref = useRef();

	const [events] = useAllPrismicDocumentsByType('event');

	const [tattoos] = useAllPrismicDocumentsByType('tattoo');

	const [flashs] = useAllPrismicDocumentsByType('flash');

	const [planches] = useAllPrismicDocumentsByType('planche');

	const [planchesOrder] = useSinglePrismicDocument('planche_order')

	dispatch(changeEventsData(events));
	dispatch(changeTattoosData(tattoos));
	dispatch(changeFlashsData(flashs));
	dispatch(changePlanchesData(planches));
	dispatch(changePlanchesOrderData(planchesOrder));

	const handleClick = () => {
		ref.current.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" })
	}

	return (
		<div ref={ref} className="lg:h-screen h-dvh w-screen bg-gray-800 overflow-y-scroll overflow-x-hidden">
			<Navbar goToTop={handleClick}/>
			<ContentWindow goToTop={handleClick}/>
			<Footer goToTop={handleClick}/>
		</div>
	);
}

export default App;