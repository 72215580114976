import { configureStore } from "@reduxjs/toolkit";
import { contactReducer, changeEventFull, changeDropdownSelection, changeLocation, changeSelected, changeWaitList, changeSelectedFlash, changeObject } from "./slices/contactSlice"
import { dataReducer, changeEventsData, changeTattoosData, changeFlashsData, changePlanchesData, changePlanchesOrderData } from "./slices/dataSlice";

const store = configureStore({
	reducer : {
		contact: contactReducer,
		data: dataReducer,
	}
});

export {
	store,
	changeEventFull,
	changeDropdownSelection,
	changeLocation,
	changeSelected,
	changeWaitList,
	changeEventsData,
	changeTattoosData,
	changeFlashsData,
	changePlanchesData,
	changePlanchesOrderData,
	changeSelectedFlash,
	changeObject,
}