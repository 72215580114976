import { createPortal } from "react-dom";
import { useEffect } from "react";
import TattooPreview from "./TattooPreview";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";


function TattooModal({ onClose, tattoo, onClickTattooNavigation, selectedPicture, handleClickPreview }) {
	
	useEffect(() => {
		document.body.classList.add('overflow-hidden');

		return () => document.body.classList.remove('overflow-hidden');
	}, []);

	return createPortal(
		<div className="fixed inset-0 flex items-center justify-center z-20">
			<div className="fixed inset-0 bg-[#101016] opacity-80" onClick={onClose} />
			<div className="lg:w-[50vw] lg:h-auto
								fixed flex flex-row h-auto w-[100vw] justify-center">
				<GoChevronLeft className="text-white lg:text-[5vw] text-[10vw] mt-[30%] cursor-pointer transition-colors duration-300 hover:text-[#ff9600]" onClick={() => onClickTattooNavigation(-1)} />
				<img src={selectedPicture} className="mr-[2%] lg:size-3/5 size-[70%]" />
				<div className="lg:w-[6vw] w-[15vw]">
					<TattooPreview src={tattoo.imageOne} isActive={selectedPicture === tattoo.imageOne} handleClick={handleClickPreview}/>
					<TattooPreview src={tattoo.imageTwo} isActive={selectedPicture === tattoo.imageTwo} handleClick={handleClickPreview} />
					<TattooPreview src={tattoo.imageThree} isActive={selectedPicture === tattoo.imageThree} handleClick={handleClickPreview} />
					<TattooPreview src={tattoo.imageFour} isActive={selectedPicture === tattoo.imageFour} handleClick={handleClickPreview} />
				</div>
				<GoChevronRight className="text-white lg:text-[5vw] text-[10vw] mt-[30%] cursor-pointer transition-colors duration-300 hover:text-[#ff9600]" onClick={() => onClickTattooNavigation(1)} />
			</div>
		</div>,
		document.body
	)
}

export default TattooModal;