import classNames from "classnames";

function Tattoo ({isActive, tattoo, handleClick}) {

	const classes = classNames(
		"w-fit h-auto transition-all duration-300 ",
		isActive ? "brightness-100 hover:scale-105 cursor-pointer" : "brightness-50"
	);

	return (
		<div className="w-full mb-[1%] mr-[1%] overflow-hidden">
			<img src={tattoo.imageOne} className={classes} onClick={() => handleClick(tattoo)} />
		</div>
	)
}

export default Tattoo;