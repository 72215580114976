import SunFull from "../images/Asset_soleil_plein.svg"
import SunEmpty from "../images/Asset_soleil_vide.svg"

function RadioButton ({text, isChecked, value, change}) {
	const onChange = (event) => {
		change(event.target.value);
	};
	
	return (
		<label className="lg:w-[30%] 
							w-[50%] flex flex-row flex-wrap">
			<input type="radio" className="invisible" value={value} checked={isChecked} onChange={onChange}/>
			{isChecked ? <img src={SunFull} className="lg:w-[1.3vw] lg:h-[1.3vw] lg:mt-[1.5%] w-[4.5vw] h-[4.5vw] cursor-pointer inline-block mr-[3%]" /> : <img src={SunEmpty} className="lg:w-[1.3vw] lg:h-[1.3vw] lg:mt-[1.5%] w-[4.5vw] h-[4.5vw] cursor-pointer inline-block mr-[3%]"/>}
			{text}
		</label>
	);
}

export default RadioButton;