import plancheBackground from "../images/Img_texture_flashbook.png"

function Planche ({children}) {
	return (
		<div className="aspect-[10/6] mb-[1%] bg-black w-[90%] grid grid-flow-row grid-cols-10 grid-rows-6 bg-contain" style={{backgroundImage: `url(${plancheBackground})`}} >
			{children}
		</div>
	)
}

export default Planche;