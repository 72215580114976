import SunFull from "../images/Asset_soleil_plein.svg"
import Stamp from "../images/Asset_stamp_COMPLET.svg"
import classNames from 'classnames';
import { useDispatch } from "react-redux";
import { changeDropdownSelection, changeLocation, changeEventFull } from "../store";
import { useContext } from "react";
import NavigationContext from "../context/navigation";
import MobileContext from "../context/mobile";

function Event ({city, place, full, date, event, goToTop}) {
	const { navigate } = useContext(NavigationContext);
	const { isMobile } = useContext(MobileContext);
	const dispatch = useDispatch();

	const randomNumberInRange = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const fullClassNames = classNames(
		'w-[7.5vw] mr-[2%]',
		!full && 'invisible'
	)

	const buttonClasses = classNames(
		"py-1 lg:border-[3px] border-2 border-[#ff9600] text-[#ff9600] lg:text-[1.3vw] text-[5vw] lg:w-[11%] rounded transition-colors duration-300 hover:bg-[#ff9600] hover:text-white lg:px-[1px] px-4",
		full ? "hover:text-white opacity-50 hover:opacity-100" : "text-[1.4vw]"
	);

	const handleClick = () => {
		dispatch(changeDropdownSelection(event));
		dispatch(changeLocation("Guest"));
		dispatch(changeEventFull(event.full))
		navigate("/contact");
		goToTop();
	};

	const content = () => {

		if (!isMobile)
		{
			return (
				<div className="w-full flex flex-row mb-[0.7%] text-white ml-[15%]">
					<div className="flex flex-row flex-wrap w-[30%] py-2">
						<p className="font-bold text-[1.4vw] mr-[2%]">{city}</p>
						<img src={SunFull} className="w-[1.5vw] h-[1.5vw] mt-[1.6%] " />
						<p className="ml-[2%] text-[1.4vw]">{place}</p>
					</div>
					<img src={Stamp} className={fullClassNames} style={{ transform: `rotate(${randomNumberInRange(-5, 5)}deg)` }} />
					<p className=" w-[30%] text-[1.4vw] py-2">{date}</p>
					<button className={buttonClasses} onClick={handleClick}>{full ? "M'ALERTER" : "RÉSERVER"}</button>
				</div>
			)
		}
		else
		{
			return (
				<div className="w-full flex flex-col items-center text-white mb-[15%]">
					<img src={SunFull} className="size-[5vw]"/>
					<p className="font-bold text-[5vw]">{city}</p>
					{!full ? 
					<div className="flex flex-col items-center mb-[2%]">
						<p className="text-gray-200 text-[5vw]">{place}</p>
							<p className="text-gray-200 text-[5vw]">{date}</p>
					</div> :
						<img src={Stamp} className="w-[30vw] mb-[3%]" style={{ transform: `rotate(${randomNumberInRange(-2, 2)}deg)` }} />
					}
					<button className={buttonClasses} onClick={handleClick}>{full ? "M'ALERTER" : "RÉSERVER"}</button>
				</div>
			)
		}
	};

	return content()
}

export default Event;