import Link from "./Link"
import Logo from "../images/Logo_AE.png"
import Background from "../images/Img_texture-papier_noir.png"

function Navbar({goToTop}) {
	const links = [
		{ label: 'FLASHBOOK', path: '/flashbook' },
		{ label: 'EVENTS', path: '/events' },
		{ label: 'TATTOOS', path: '/tattoos' },
		{ label: 'FAQ', path: '/faq' },
		{ label: 'CONTACT', path: '/contact' },
	];

	const renderedLinks = links.map((link) => {
		return <Link className="lg:text-[1.4vw]
									text-[2.5vw] transition-colors duration-300 ease-in-out hover:text-[#ff9600]"
			activeClassName="text-[#ff9600] font-bold"
			to={link.path}
			key={link.label}
			goToTop={goToTop}
		>
			{link.label}
		</Link>
	})

	return (
		<div className="lg:h-[8%]
							w-screen h-[6%] sticky top-[0%] flex flex-row flex-wrap items-center z-40">
			<div className="absolute w-full h-full z-0 bg-contain" style={{ backgroundImage: `url(${Background})` }} />
			
			<div className="flex-1 flex ml-[1%] h-full items-center z-10">
				<Link to="/" key="FRONTPAGE" goToTop={goToTop} className="h-[85%]">
					<img src={Logo} className="w-[100%] h-[100%] object-contain"/>
				</Link>
			</div>
			<div className="lg:w-[52%] w-[14%] z-10"></div>
			<div className="lg:w-[40%]
								flex flex-row flex-wrap h-[100%] w-[70%] mr-[3%] justify-between items-center font-Montserrat z-10">
				{renderedLinks}
			</div>
		</div>
	)
}

export default Navbar;