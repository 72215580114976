import { useState, useContext, useLayoutEffect } from "react";
import emailjs from "@emailjs/browser"
import Dropdown from "./Dropdown";
import RadioButtons from "./RadioButtons";
import { useDispatch, useSelector } from "react-redux";
import { changeDropdownSelection, changeEventFull, changeSelected, changeWaitList, changeLocation, changeEventsData, changeObject, changeSelectedFlash } from "../store";
import Background from "../images/Img_texture-papier_seamless_4K.png"
import Popup from "./Popup";
import NavigationContext from "../context/navigation";
import MobileContext from "../context/mobile";
import Checkbox from "./Checkbox";
import ReCAPTCHA from "react-google-recaptcha";

function ContactPage({goToTop}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [isOver18, setIsOver18] = useState(false);
	const [captchaValidated, setCaptchaValidated] = useState(false);
	const [popupMessage, setPopupMessage] = useState(null);

	const { navigate, currentPath } = useContext(NavigationContext);
	const { isMobile } = useContext(MobileContext);

	
	const dispatch = useDispatch();
	const {eventFull, dropdownSelection, location, selected, waitList, eventsData, selectedFlash } = useSelector((state) => {
		return {
			eventFull: state.contact.eventFull,
			dropdownSelection: state.contact.dropdownSelection,
			location: state.contact.location,
			selected: state.contact.selected,
			waitList: state.contact.waitList,
			eventsData: state.data.eventsData,
			selectedFlash: state.contact.selectedFlash,
		}
	})
	
	useLayoutEffect (() => {
		return (() => {
			dispatch(changeEventFull(false));
			dispatch(changeWaitList(false));
			setEmail("");
			setMessage("");
			setIsOver18(false);
			setPopupMessage(null);
			setName("");
			dispatch(changeDropdownSelection(null));
			dispatch(changeLocation("Salon"));
			dispatch(changeSelected("Projet"));
			dispatch(changeSelectedFlash("0"));
		})
	}, [currentPath])

	const renderedEventsData = eventsData?.map((event) => {
		return {
			label: event.data.city[0].text + ' ' + event.data.dates[0].text,
			value: event.data.city[0].text + ' ' + event.data.dates[0].text,
			full: event.data.full,
			city: event.data.city[0].text,
			place: event.data.place[0].text,
			dates: event.data.dates[0].text,
			startDate: new Date(event.data.startdate),
			id: event.id,
		}
	})

	const sortedEventsData = renderedEventsData?.sort((a, b) => a.startDate - b.startDate);

	const handleClickFAQ = (event) => {
		if (event.metaKey || event.ctrlKey)
			return;
		event.preventDefault();

		navigate("/faq");
		goToTop();
	};

	const onChange = (value) => {
		dispatch(changeSelected(value));
	};

	const onChangeLocation = (value) => {
		dispatch(changeLocation(value));
		dispatch(changeEventFull(false));
		dispatch(changeDropdownSelection(null));
	};

	const handleSelect = (option) => {
		dispatch(changeDropdownSelection(option));
		dispatch(changeEventFull(option.full));
	}

	const onChangeWaitList = () => {
		dispatch(changeWaitList(!waitList));
	}

	const onChangeIsOver18 = () => {
		setIsOver18(!isOver18);
	}

	const onChangeCaptcha = () => {
		setCaptchaValidated(true);
	}

	const onExpiredCaptcha = () => {
		setCaptchaValidated(false);
	}

	const onClosePopup = () => {
		setPopupMessage(null);
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		let object = (waitList ? "Liste d'attente " : "");

		object += (location === "Salon" ? "Salon " : (dropdownSelection?.value ? dropdownSelection?.value + " " : ""))

		if (selected === "Projet")
			object += "Projet sur mesure"
		else
			object += "Réservation flash n°" + selectedFlash;

		if (!name || !email || !message || !isOver18 || (location === "Guest" && !dropdownSelection))
		{
			setPopupMessage("Vous devez remplir les champs obligatoires");
			return ;
		}
		emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_KEY, {
			from_name : name,
			reply_to: email,
			message: message,
			obj: object,
		}).then(
			(response) => {
				setPopupMessage("Message bien envoyé")
			},
			(error) => {
				console.log(error);
				setPopupMessage("Il y a eu un problème pendant l'envoie du message")
			},
		);

	};

	return (
		<div className="w-full h-fit min-h-screen bg-white flex flex-col items-center pb-[3%] bg-contain font-Montserrat" style={{ backgroundImage: `url(${Background})` }}>
			{popupMessage && <Popup onClose={onClosePopup} text={popupMessage}/>}
			<p className="lg:text-[2.5vw] lg:w-[70%] lg:mb-[1%] lg:mt-[3%]
						 text-[5.2vw] mt-[5%] w-[90%] mb-[3%] text-center font-Naeris text-[#ff9600]">ME CONTACTER</p>
			<p className="lg:text-[1.2vw] lg:w-[60%] lg:ml-[2%] lg:mb-[2%]
							text-black text-[3vw] w-[85%] ml-[12%] mb-[5%] italic">Avant tout, n'hésite pas à lire <span className="text-[#ff9600] cursor-pointer " onClick={handleClickFAQ}>ma FAQ</span> qui répondra peut-être à certaines de tes questions ! <br /> Pour toute demande de renseignements hors réservation de projet, tu peux <br /> <a href="https://www.instagram.com/naeris.ttt/" className="cursor-pointer text-[#ff9600]">me contacter sur Instagram</a>.</p>
			<RadioButtons selected={selected} location={location} onChange={onChange} onChangeLocation={onChangeLocation}/>
			<form className="w-screen flex flex-col items-center" onSubmit={handleSubmit}>
				{location === "Guest" ? <div className="lg:flex-row lg:text-[1.2vw] lg:w-[100%] lg:mb-[1%]
															flex flex-col flex-wrap text-[3.2vw] w-[90%] mb-[5%]">
					<p className="lg:mb-[0%] mb-[3%] lg:w-[28.5%] lg:mr-[8.3%] lg:text-right py-1">Évènement<span className="text-[#ff9600]">*</span></p>
					<div className="lg:w-[20%] lg:ml-[0%]
										ml-[3%] w-[60%] justify-end border-[1px] rounded border-black hover:border-[#ff9600] py-1 px-2 bg-white">
						<Dropdown options={sortedEventsData} value={dropdownSelection} onChange={handleSelect} />
					</div>
				</div> : null}
				{eventFull ? <div className="lg:w-[35%] lg:text-[1.2vw] lg:mb-[1%]
												flex flex-col flex-wrap w-[90%] text-[3.2vw] lg:ml-[8.5%] ml-[3.5%] mb-[3%] italic">
					<p>Cet évènement est malheureusement complet. <br /> <br /> Tu as toujours la possiblité de t'inscrire sur liste <br />d'attente, auquel cas je te recontacterai à l'annonce <br />de mes prochaines dates dans cette ville.</p>
					<br/>
					<Checkbox text="M'inscrire sur liste d'attente" value={waitList} change={onChangeWaitList} margins="mt-[1.3%] mr-[3%]"/>
				</div> : null}
				{((waitList && eventFull) || !eventFull) ? 
					<div className="w-full flex flex-col items-center">
						<div className="lg:flex-row lg:text-[1.2vw] lg:w-[100%] lg:mb-[1%]
											flex flex-col flex-wrap text-[3.2vw] w-[90%] mb-[5%]">
							<p className="lg:mb-[0%] mb-[1%] lg:w-[28.5%] lg:mr-[8.3%] lg:text-right py-1">Prénom<span className="text-[#ff9600]">*</span></p>
							<input className="lg:w-[20%] lg:ml-[0%]
												w-[70%] ml-[3%] border-black outline-none focus:border-[#ff9600] border-[1px] rounded py-1 px-2" value={name} onChange={(event) => setName(event.target.value)}/>
						</div>
						<div className="lg:flex-row lg:text-[1.2vw] lg:w-[100%] lg:mb-[1%]
											flex flex-col flex-wrap text-[3.2vw] w-[90%] mb-[5%]">
							<p className="lg:mb-[0%] mb-[1%] lg:w-[28.5%] lg:mr-[8.3%] lg:text-right py-1">Adresse mail<span className="text-[#ff9600]">*</span></p>
							<input className="lg:w-[30%] lg:ml-[0%]
												w-[75%] ml-[3%] border-black outline-none focus:border-[#ff9600] border-[1px] rounded py-1 px-2" type="email" value={email} onChange={(event) => setEmail(event.target.value)}/>
						</div>
						<div className="lg:flex-row lg:text-[1.2vw] lg:w-[100%] lg:mb-[1%]
											flex flex-col flex-wrap text-[3.2vw] w-[90%] mb-[3%]">
							<p className="lg:mb-[0%] mb-[3%] lg:w-[28.5%] lg:mr-[8.3%] lg:text-right py-1">{selectedFlash === 0 ? "Description du projet" : "Message"}<span className="text-[#ff9600]">*</span><br /> <br/>
								{!isMobile ? <span className="lg:text-[1vw] text-[2.5vw] lg:ml-[10%] lg:mb-[0%]">
									Toute {selectedFlash === "0" ? "prise de contact" : "réservation de flash"} <br/>devra contenir les informations <br/>suivantes, sans quoi je ne serai <br/>pas en mesure de répondre <br/>à la demande : <br/> <br/>
									{selectedFlash === "0" ? <p>- Description détaillée du projet<br /></p> : ""}
									- Taille et emplacement imaginé <br/>
									{selectedFlash === "0" ? <p>- Budget à respecter <br /></p> : ""}
									- Jours de disponibilité <br/>
									<br/>
									Les images (référence, emplacement) <br /> seront à envoyer par mail <br /> après réception <br /> d'une réponse <br />automatique de ma part.
								</span> :
									<span className="lg:text-[1vw] text-[2.5vw] lg:ml-[10%] lg:mb-[0%]">
										Toute {selectedFlash === "0" ? "prise de contact" : "réservation de flash"} devra contenir les informations suivantes, <br/>sans quoi je ne serai pas en mesure de répondre à la demande : <br />
										{selectedFlash === "0" ? <p>- Description détaillée du projet<br /></p> : ""}
										- Taille et emplacement imaginé <br />
										{selectedFlash === "0" ? <p>- Budget à respecter <br /></p> : ""}
										- Jours de disponibilité <br />
										Les images (référence, emplacement) seront à envoyer par mail après réception d'une réponse automatique de ma part.
									</span>}
							</p>							
							<textarea className="lg:w-[45%] lg:ml-[0%]
													w-[90%] ml-[3%] min-h-[64dvh] max-h-[64dvh] border-black outline-none focus:border-[#ff9600] border-[1px] rounded py-1 px-2" value={message} onChange={(event) => setMessage(event.target.value)}/>
						</div>
						<label className="lg:w-[30%] lg:text-[1.2vw] lg:ml-[3.5%]
											w-[90%] mb-[1%] text-[3.2vw] flex flex-row flex-wrap italic">
							<Checkbox text="J'atteste être majeur(e)" value={isOver18} change={onChangeIsOver18} margins="lg:mt-[1.3%] mt-[0.6%] mr-[3%]" />
						</label>
						
						<div className="lg:w-[30%] w-[90%] lg:ml-[3.5%]">
							<ReCAPTCHA className="" sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={onChangeCaptcha} onExpired={onExpiredCaptcha}/>
						</div>
						{isOver18 && captchaValidated? <button className="lg:border-[3px] lg:text-[1.3vw] lg:ml-[50%]
														 rounded ml-[45%] hover:text-white text-[4vw] px-4 py-1 border-2 border-[#ff9600] text-[#ff9600] transition-colors duration-300 hover:bg-[#ff9600]" onClick={handleSubmit}>ENVOYER</button>  
						: <div className="lg:h-[5dvh] h-[4.5dvh] w-[10%]"/>}
					</div>
				: null}
			</form>
		</div>
	)
}

export default ContactPage;